import React from "react";
import SystemBanner04 from "../../../assets/images/Systembanner/SystemBanner04.png";
import "../../../assets/css/Order.css";
import { useMediaQuery } from "react-responsive";

function SystemContent04() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <div id="Content_Wrap">
      <div className="table_mockup" style={{ paddingTop: isTabletOrMobile ? 10 : 20 }}>
        <img src={SystemBanner04} alt="" />
      </div>
      <p>원하는 수량만큼 추가 후 주문 가능</p>
    </div>
  );
}

export default SystemContent04;
