import React, { useEffect, useRef, useState } from "react";
import Section from "../common/Section.tsx";

import { useMediaQuery } from "react-responsive";

import styles from "./Order.module.css";

import Patten from "../../assets/images/Systembanner/patten.png";
import IconPlus from "../../assets/images/icon/plus.png";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SystemContent01 from "./Swiper/SystemContent01.tsx";
import SystemContent02 from "./Swiper/SystemContent02.tsx";
import SystemContent03 from "./Swiper/SystemContent03.tsx";
import SystemContent04 from "./Swiper/SystemContent04.tsx";
import SystemContent05 from "./Swiper/SystemContent05.tsx";

const tabs = ["장바구니", "주문내역", "더치페이 계산기", "주문접수 완료", "다국어 지원"];
// const mobileTabs = ["장바구니", "주문내역", "직원호출", "수량선택", "다크모드"];

const Divider = () => <img src={Patten} alt="divider" />;

const Test = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const swiperRef = useRef<typeof Swiper>(null);

  const [active, setActive] = useState(0);

  const handleChangeIndex = (index: number) => {
    setActive(index);
  };

  const handleSlideChange = (swiper) => {
    setActive(swiper.activeIndex);
  };

  useEffect(() => {
    if (swiperRef) {
      swiperRef.current.slideTo(active);
    }
  }, [active]);

  return (
    <Section title="테이블 오더 시스템" className={styles.orderSection}>
      <div className={styles.orderMain} id="order_main">
        {!isTabletOrMobile && (
          <div className={styles.paginationWrapper}>
            <ul>
              {tabs.map((tab, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`${styles.item} ${active === index ? styles.on : ""}`}
                    onClick={() => handleChangeIndex(index)}
                  >
                    {active === index && <img src={IconPlus} alt="ic_plus" className="mr-7" />}
                    {tab}
                  </li>
                  {index !== tabs.length - 1 && (
                    <li>
                      <Divider />
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
        <div className={styles.swiperWrapper}>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            className={styles.orderSwiper}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            onSlideChange={handleSlideChange}
          >
            <SwiperSlide>
              <SystemContent01 />
            </SwiperSlide>
            <SwiperSlide>
              <SystemContent02 />
            </SwiperSlide>
            <SwiperSlide>
              <SystemContent03 />
            </SwiperSlide>
            <SwiperSlide>
              <SystemContent04 />
            </SwiperSlide>
            <SwiperSlide>
              <SystemContent05 />
            </SwiperSlide>
          </Swiper>
        </div>
        {isTabletOrMobile && (
          <div>
            <ul className={styles.mobileTab}>
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  className={`${styles.mobileItem} ${active === index ? styles.on : ""}`}
                  onClick={() => handleChangeIndex(index)}
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Section>
  );
};

export default Test;
