import React from "react";
import SystemBanner05 from "../../../assets/images/Systembanner/SystemBanner05.png";
import "../../../assets/css/Order.css";
import { useMediaQuery } from "react-responsive";

function SystemContent05() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div id="Content_Wrap">
      <div className="table_mockup" style={{ paddingTop: isTabletOrMobile ? 10 : 20 }}>
        <img src={SystemBanner05} alt="" />
      </div>
      <p>편안하게 눈으로 볼수있도록 다크모드 설정 가능</p>
    </div>
  );
}

export default SystemContent05;
